@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.1em;
  color: #000;
  font-family: source-han-serif-japanese, serif;
  font-style: normal;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0 20px;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .inner {
    padding: 64px 0 20px;
  }
}

.inner2 {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0 20px;
}

@media only screen and (max-width: 640px) {
  .inner2 {
    padding: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .inner2 {
    padding: 0;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  padding-bottom: 120px;
}

@media only screen and (max-width: 768px) {
  #contents_wrap {
    padding-bottom: 50px;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 768px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  max-width: 100% !important;
  float: none !important;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  font-family: source-han-serif-japanese, serif;
  font-style: normal;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  header#global_header {
    padding-top: 30px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

#hedwrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  #hedwrap {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  #hedwrap {
    padding: 20px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #hedwrap {
    padding: 68px 10px 20px;
  }
}

#header {
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 768px) {
  #header {
    box-shadow: none;
  }
}

#header #siteID {
  width: 17%;
}

@media only screen and (max-width: 1000px) {
  #header #siteID {
    order: 1;
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  #header #siteID {
    padding: 10px 0 !important;
  }
}

#header #siteID a {
  opacity: 1;
}

#header #hed_info {
  display: block;
  color: #03386f;
  width: auto;
  line-height: 20px;
}

@media only screen and (max-width: 1000px) {
  #header #hed_info {
    order: 2;
  }
}

@media only screen and (max-width: 640px) {
  #header #hed_info {
    margin: 0 auto;
    width: 181px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #header #hed_info {
    width: auto;
  }
}

#header .tel2 {
  text-align: left;
  color: #03386f;
  font-family: Times New Roman;
  line-height: 1;
  padding-bottom: 8px;
}

#header .tel2 span {
  font-size: 24px;
  font-size: 2vw;
}

@media only screen and (min-width: 1201px) {
  #header .tel2 span {
    font-size: 24px;
  }
}

@media only screen and (max-width: 640px) {
  #header .tel2 span {
    font-size: 18px;
  }
}

#header .tel2 p {
  font-size: 20px;
}

#header .tel2 i {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #header .tel2 {
    position: fixed;
    top: 13px;
    left: 15px;
    z-index: 8;
  }
  #header .tel2 a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #header .tel2 {
    text-align: left;
  }
}

#header .cont {
  font-size: 20px;
}

#header .cont p {
  padding: 16px 32px;
  background: #b94047;
}

#header .cont a {
  color: #fff;
}

#header .flxR {
  width: 68%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#nav_global {
  width: 60%;
  padding: 30px 0;
}

@media only screen and (max-width: 1000px) {
  #nav_global {
    order: 3;
    width: 100%;
    padding: 30px 0 0;
  }
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li {
  font-size: 14px;
  font-size: 1.16667vw;
}

@media only screen and (min-width: 1000px) {
  #nav_global ul li {
    font-size: 14px;
  }
}

#nav_global ul li a {
  font-weight: 400;
  color: #000;
  display: block;
  font-weight: 500;
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

.time {
  font-size: 13px;
}

.time ul {
  text-align: left;
}

.time li {
  padding-bottom: 5px;
}

.time p {
  display: flex;
}

.time span {
  background: #03386f;
  color: #fff;
  padding: 0 4px;
  margin-right: 8px;
  display: block;
  width: 70px;
  text-align: center;
}

.mainArea {
  width: 100%;
}

.mainArea img {
  width: 100% !important;
  height: auto;
}

.mainwrap {
  position: relative;
  z-index: -1;
}

.mainwrap .img_txt {
  position: absolute;
  bottom: 80px;
  right: 160px;
}

@media only screen and (max-width: 640px) {
  .mainwrap .img_txt {
    bottom: 5px;
    right: -50px;
    width: 90%;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  background-position: bottom;
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
  }
  #local-keyvisual h1 span {
    padding: 100px 0 !important;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 190px 0;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #local-keyvisual h1 span {
    padding: 100px 0 60px;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center bottom;
}

@media only screen and (max-width: 768px) {
  #local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
    background-position: center;
  }
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.mean-container .mean-bar {
  background: #03386f !important;
  position: fixed !important;
  z-index: 8 !important;
}

.mean-container .mean-nav {
  background: #03386f !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a {
  padding: 10px 5% !important;
}

.mean-container .mean-nav ul li a i {
  margin-right: 15px;
  font-size: 16px;
}

.mean-container a.meanmenu-reveal span {
  background: #fff !important;
}

.mean-push {
  display: none;
}

.fixed #nav_global {
  position: fixed;
  top: 0;
  padding: 20px 10px;
  width: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.fixed #nav_global ul {
  max-width: 1200px;
  margin: 0 auto;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  background: url(../images/common/patern.gif);
  color: #333;
  margin-top: 0;
  font-size: 14px;
  font-family: source-han-sans-japanese, sans-serif;
}

@media only screen and (max-width: 640px) {
  footer#global_footer img {
    padding: 30px 0 25px !important;
  }
}

footer#global_footer img {
  padding: 60px 0 25px;
}

footer#global_footer a {
  color: #333;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: underline;
}

footer#global_footer #nav_footer {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#global_footer #nav_footer li {
  font-family: source-han-sans-japanese, sans-serif;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 10px;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

footer#global_footer #copy {
  color: #fff;
}

footer#global_footer small {
  font-size: 14px;
}

footer#global_footer .smallbg {
  background: #03386f;
  padding: 10px 0;
}

/* box */
.h2_001 {
  color: #03386f;
  line-height: 1.7em;
  font-size: 22px;
  font-size: 1.83333vw;
}

@media only screen and (min-width: 1201px) {
  .h2_001 {
    font-size: 22px;
  }
}

.h2_001 span {
  font-size: 32px;
  font-size: 2.66667vw;
}

@media only screen and (min-width: 1201px) {
  .h2_001 span {
    font-size: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .h2_001 {
    font-size: 14px;
  }
  .h2_001 span {
    font-size: 20px;
  }
}

.h2_01 {
  color: #03386f;
  line-height: 1.7em;
  font-size: 22px;
  font-size: 1.83333vw;
}

@media only screen and (min-width: 1201px) {
  .h2_01 {
    font-size: 22px;
  }
}

.h2_01 span {
  font-size: 32px;
  font-size: 2.66667vw;
}

@media only screen and (min-width: 1201px) {
  .h2_01 span {
    font-size: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .h2_01 {
    font-size: 20px;
  }
  .h2_01 span {
    font-size: 14px;
  }
}

.h2_02 {
  color: #03386f;
  line-height: 1.7em;
  font-size: 30px;
  font-size: 2.5vw;
}

@media only screen and (min-width: 1201px) {
  .h2_02 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 640px) {
  .h2_02 {
    font-size: 18px;
  }
}

.h2_03 {
  font-size: 24px;
}

@media only screen and (max-width: 640px) {
  .h2_03 {
    font-size: 18px;
  }
}

.h2_03 span {
  font-size: 16px;
}

.h2_03 h2 {
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
}

.h2_03 h2:before {
  content: '';
  position: absolute;
  bottom: -15px;
  display: inline-block;
  width: 60px;
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #d1a128;
}

.h2_04 {
  font-size: 30px;
  padding: 10px 0;
  color: #03386f;
}

@media only screen and (max-width: 640px) {
  .h2_04 {
    font-size: 22px;
  }
}

.sec_01 {
  line-height: 32px;
}

.sec_01 .txt > div {
  font-family: source-han-sans-japanese, sans-serif;
}

@media only screen and (max-width: 640px) {
  .sec_01 .txt > div {
    text-align: left !important;
  }
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.sec_02 .txt {
  line-height: 32px;
  font-family: source-han-sans-japanese, sans-serif;
}

.sec_02 .flxL {
  width: 60%;
}

@media only screen and (max-width: 1000px) {
  .sec_02 .flxL {
    width: 50%;
  }
}

.sec_02 .flxR {
  display: block;
  width: 35%;
}

@media only screen and (max-width: 1000px) {
  .sec_02 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
    text-align: center !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

.sec_03 {
  display: flex;
  justify-content: space-between;
}

.sec_03 .txt {
  line-height: 32px;
  font-family: source-han-sans-japanese, sans-serif;
}

.sec_03 .flxL {
  display: block;
  width: 60%;
}

@media only screen and (max-width: 1000px) {
  .sec_03 .flxL {
    width: 50%;
  }
}

.sec_03 .flxR {
  width: 35%;
}

@media only screen and (max-width: 1000px) {
  .sec_03 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
    text-align: center !important;
  }
}

.sec_04 {
  background: url(../images/common/patern.gif);
  padding: 30px 0 50px;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sec_04 {
    padding: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    padding: 20px;
  }
}

.banner {
  justify-content: space-between;
}

.banner .box {
  text-align: center;
  width: 31.66%;
}

.banner .box img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .banner {
    display: block;
  }
  .banner .box {
    padding: 10px 0;
  }
  .banner .box {
    width: 100% !important;
  }
}

.banner2 {
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .banner2 {
    display: block;
  }
  .banner2 .box {
    width: 100% !important;
    padding: 10px;
  }
}

.banner2 .box {
  width: 31.66%;
}

.banner2 .box img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .banner2 {
    display: block;
  }
  .banner2 .box {
    padding: 10px 0;
  }
  .banner2 .box {
    width: 100% !important;
  }
}

.info {
  font-family: source-han-sans-japanese, sans-serif;
  background: #fff;
  padding: 60px 16%;
  margin: 0 100px;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .info {
    padding: 40px;
  }
}

@media only screen and (max-width: 640px) {
  .info {
    width: auto;
    padding: 0;
    margin: 0;
    background: none;
  }
  .info dl {
    display: block !important;
  }
  .info dt {
    width: 100% !important;
  }
  .info dd {
    width: 100% !important;
    background-color: transparent !important;
    text-align: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .info {
    width: auto;
    padding: 0;
    margin: 0;
    background: none;
  }
  .info dl {
    display: block !important;
  }
  .info dt {
    width: 100% !important;
  }
  .info dd {
    width: 100% !important;
    background-color: transparent !important;
    text-align: center;
  }
}

.info dt {
  min-width: 100px;
  border: none !important;
  background: #03386f !important;
  color: #fff !important;
  display: block !important;
  text-align: center !important;
}

.info dd {
  border: none !important;
}

.info dl {
  align-items: center !important;
  padding: 6px !important;
}

.news {
  height: 240px;
  overflow: scroll;
  overflow-x: hidden;
}

@media only screen and (max-width: 640px) {
  .news {
    margin: 0;
    padding: 0 10px;
  }
  .news .blog_text {
    display: block !important;
  }
  .news .blog_date {
    width: 100% !important;
  }
  .news h3 {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .news {
    padding: 0 10px;
  }
  .news .blog_text {
    display: block !important;
  }
  .news .blog_date {
    width: 100% !important;
  }
  .news h3 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .news .blog_text {
    display: block !important;
  }
  .news .blog_date {
    width: 100% !important;
  }
  .news h3 {
    width: 100% !important;
  }
}

.news .blog_photo {
  display: none;
}

.news .detail {
  display: none;
}

.news .blog_text {
  display: flex;
  padding: 20px 0 !important;
}

.news .blog_list {
  border: none !important;
}

.news .blog_date {
  color: #333 !important;
  width: 20%;
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 16px;
}

.news h3 {
  font-family: source-han-sans-japanese, sans-serif;
  width: 80%;
  margin-bottom: 0 !important;
}

.news h3 a {
  color: #333;
}

.news dl {
  font-family: source-han-sans-japanese, sans-serif;
  border-bottom: 1px dashed #ccc;
}

@media only screen and (max-width: 768px) {
  .news dl {
    display: block !important;
  }
}

.news dt {
  border: none !important;
  background: transparent !important;
}

@media only screen and (max-width: 768px) {
  .news dt {
    display: block !important;
    width: 100% !important;
  }
}

.news dd {
  border: none !important;
  padding: 20px 0 !important;
  background: transparent !important;
}

@media only screen and (max-width: 768px) {
  .news dd {
    width: 100% !important;
    padding: 8px !important;
  }
}

.news_wrap {
  background: rgba(255, 255, 255, 0.8);
  width: 1000px;
  padding: 60px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .news_wrap {
    width: auto;
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .news_wrap {
    width: auto;
    padding: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .news_wrap {
    width: 100%;
  }
}

.sns_wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sns_wrap {
    display: block;
  }
  .sns_wrap .flxL {
    width: 100% !important;
  }
  .sns_wrap .flxR {
    width: 100% !important;
  }
}

.sns_wrap .flxL {
  width: 48%;
}

.sns_wrap .flxR {
  width: 48%;
}

.sns_wrap .sns_title {
  width: 100%;
  margin: 0 auto;
  background: url(../images/common/sns_bg.jpg);
  color: #fff;
  line-height: 2;
  padding-left: 10px;
  border-radius: 5px;
  background-repeat: no-repeat;
  font-size: 24px;
  margin-bottom: 15px;
}

.sns_wrap .sns_title span {
  font-size: 14px;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sns_wrap .sns_title {
    text-align: center;
    background-position: center;
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  .sns_wrap .sns_title {
    width: auto;
    background-size: cover;
    padding: 2px 10px;
    font-size: 18px;
  }
}

.sns_wrap .fb {
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sns_wrap .fb iframe {
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  .sns_wrap .fb iframe {
    width: 340px !important;
  }
}

@media only screen and (max-width: 640px) {
  .insta {
    margin-top: 20px;
    width: auto;
  }
}

.insta .sns_list {
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.insta .sns_list > div {
  border: none;
  width: 33%;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .insta .sns_list > div {
    width: 28%;
  }
}

.insta .sns_text {
  display: none;
}

.insta .sns_photo {
  padding: 0 !important;
  width: 100% !important;
}

.insta {
  margin-left: -2.43902% !important;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .insta {
    margin-left: -2.43902% !important;
  }
}

@media only screen and (max-width: 640px) {
  .insta {
    margin-left: 0 !important;
  }
}

.insta .sns_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.insta .sns_list > div {
  width: 30.89431% !important;
  margin-left: 2.43902% !important;
  margin-bottom: 2.43902% !important;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .insta .sns_list > div {
    width: 30.89431% !important;
    margin-left: 2.43902% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .insta .sns_list > div {
    width: 48% !important;
    margin-left: 0 !important;
    justify-content: space-between;
  }
}

.insta .sns_list > div img {
  width: 100% !important;
}

/* title */
.detail {
  font-family: source-han-sans-japanese, sans-serif;
}

@media only screen and (max-width: 768px) {
  .table_01 {
    width: 100%;
  }
}

.table_01 dt {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
}

.table_01 dd {
  border: none !important;
  background-color: transparent !important;
  text-align: right;
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .table_01 {
    padding: 0 10px;
  }
  .table_01 dl {
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .table_01 {
    padding: 0 10px;
  }
}

.table_02 dt {
  border: none !important;
  background-color: transparent !important;
  font-size: 28px !important;
}

.table_02 dd {
  border: none !important;
  background-color: transparent !important;
  text-align: right;
  font-size: 28px !important;
}

.table_02 dl {
  border-bottom: 1px dotted #d1a128;
}

.table_03 {
  font-family: source-han-sans-japanese, sans-serif;
}

.table_03 dt {
  background: #f7f6fb !important;
}

@media only screen and (max-width: 640px) {
  .table_03 dl {
    display: block !important;
  }
  .table_03 dt {
    width: 100% !important;
    display: block !important;
    border-right: 1px solid #ccc !important;
    text-align: center;
  }
  .table_03 dd {
    width: 100% !important;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .table_04 {
    width: 100%;
  }
}

.table_04 dt {
  border: none !important;
  background-color: transparent !important;
}

.table_04 dd {
  border: none !important;
  background-color: transparent !important;
  text-align: right;
}

@media only screen and (max-width: 640px) {
  .table_04 {
    padding: 0 10px;
  }
  .table_04 dl {
    width: 100%;
  }
}

.menuwrap .cont1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .menuwrap .cont1 {
    display: block;
  }
}

@media only screen and (max-width: 1000px) {
  .menuwrap .cont1 {
    justify-content: space-between;
  }
}

.menuwrap .cont1 .wrap {
  width: 48%;
}

@media only screen and (max-width: 768px) {
  .menuwrap .cont1 .wrap {
    width: 100% !important;
  }
}

.menuwrap .cont1 .wrap .wrapbox {
  border: 1px dotted #d1a128;
  padding: 20px;
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .menuwrap .cont1 .wrap .wrapbox {
    padding: 10px !important;
  }
}

.menuwrap .cont1 .wrap .wrapbox .txt {
  padding: 10px 0;
}

@media only screen and (max-width: 640px) {
  .menuwrap .cont1 .wrap .wrapbox .txt {
    padding: 0 10px;
  }
}

.menuwrap .img {
  width: 48%;
}

@media only screen and (max-width: 768px) {
  .menuwrap .img {
    width: 100%;
  }
}

.menuwrap .img img {
  width: 500px !important;
  height: 500px !important;
  object-fit: cover;
}

@media only screen and (max-width: 640px) {
  .menuwrap .img img {
    width: 100% !important;
    height: auto !important;
  }
}

.menuwrap2 .cont1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 768px) {
  .menuwrap2 .cont1 {
    display: block;
  }
}

@media only screen and (max-width: 1000px) {
  .menuwrap2 .cont1 {
    justify-content: space-between;
  }
}

.menuwrap2 .cont1 .wrap {
  width: 48%;
}

@media only screen and (max-width: 768px) {
  .menuwrap2 .cont1 .wrap {
    width: 100% !important;
  }
}

.menuwrap2 .cont1 .wrap .wrapbox {
  border: 1px dotted #d1a128;
  padding: 20px;
  background: #f7f6fb;
}

@media only screen and (max-width: 640px) {
  .menuwrap2 .cont1 .wrap .wrapbox {
    padding: 10px !important;
  }
}

.menuwrap2 .cont1 .wrap .wrapbox .txt {
  padding: 10px 0;
}

@media only screen and (max-width: 640px) {
  .menuwrap2 .cont1 .wrap .wrapbox .txt {
    padding: 0 10px;
  }
}

.menuwrap2 .img {
  width: 48%;
}

@media only screen and (max-width: 768px) {
  .menuwrap2 .img {
    width: 100%;
  }
}

.menuwrap2 .img img {
  width: 500px !important;
  height: 500px !important;
  object-fit: cover;
}

@media only screen and (max-width: 640px) {
  .menuwrap2 .img img {
    width: 100% !important;
    height: auto !important;
  }
}

.menuinner {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 60px;
}

@media only screen and (max-width: 640px) {
  .menuinner {
    width: auto;
    padding: 0 10px 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .menuinner {
    width: auto;
    padding: 0 10px 30px;
  }
}

.sec_05 {
  display: flex;
  justify-content: space-around;
}

.sec_05 .flxL {
  width: 48%;
}

.sec_05 .flxR {
  width: 25%;
}

@media only screen and (max-width: 640px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .img {
    padding-top: 10px;
  }
  .sec_05 .flxL {
    width: 100% !important;
  }
  .sec_05 .flxR {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .sec_06 {
    display: block !important;
  }
  .sec_06 .box {
    width: 100% !important;
  }
  .sec_06 h3 {
    font-size: 16px;
  }
}

.sec_06 h3 {
  font-size: 18px;
  border-bottom: 1px dotted #d1a128;
  padding: 10px 0;
  font-weight: bold;
}

.sec_06 .box {
  width: 48%;
  padding: 0 40px;
  font-family: source-han-sans-japanese, sans-serif;
}

.sec_06 .box img {
  width: 500px !important;
  height: 500px !important;
  object-fit: cover;
}

@media only screen and (max-width: 640px) {
  .sec_06 .box img {
    width: 100% !important;
    height: auto !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sec_06 .box img {
    width: 100% !important;
    height: auto !important;
  }
}

.sec_06 .box > article > div {
  padding: 10px 0 !important;
}

@media only screen and (max-width: 640px) {
  .sec_06_2 {
    display: block !important;
  }
  .sec_06_2 .box {
    width: 100% !important;
  }
  .sec_06_2 h3 {
    font-size: 16px;
  }
}

.sec_06_2 h3 {
  font-size: 18px;
  border-bottom: 1px dotted #d1a128;
  padding: 10px 0;
  font-weight: bold;
}

.sec_06_2 .box {
  width: 48%;
  padding: 0 40px;
  font-family: source-han-sans-japanese, sans-serif;
}

.sec_06_2 .box img {
  width: 500px !important;
  height: auto !important;
  object-fit: cover;
}

@media only screen and (max-width: 640px) {
  .sec_06_2 .box img {
    width: 100% !important;
    height: auto !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sec_06_2 .box img {
    width: 100% !important;
    height: auto !important;
  }
}

.sec_06_2 .box > article > div {
  padding: 10px 0 !important;
}

.sec_07 {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .sec_07 {
    display: block;
  }
  .sec_07 .flxL {
    width: 100% !important;
  }
  .sec_07 .flxR {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sec_07 {
    justify-content: space-around;
  }
  .sec_07 .flxR {
    width: 50% !important;
  }
}

.sec_07 .flxL {
  width: 40%;
}

.sec_07 .flxL img {
  width: 400px !important;
  height: 400px !important;
  object-fit: cover;
  object-position: 0% 20%;
}

.sec_07 .flxR {
  width: 60%;
}

.sec_07 .flxR .txt > div {
  font-size: 24px;
  border-bottom: 1px dotted #d1a128;
  padding: 10px 0;
}

.sec_07 .flxR .txt > div span {
  font-size: 14px;
}

@media only screen and (max-width: 640px) {
  .sec_07 .flxR .txt > div {
    font-size: 18px;
  }
}

.sec_07 .flxR .txt2 > div {
  padding: 10px 0;
  font-family: source-han-sans-japanese, sans-serif;
}

div.customer > div {
  margin-top: 0;
}

.sec_08 {
  font-family: source-han-sans-japanese, sans-serif;
  border-bottom: 1px dotted #d1a128;
  padding-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  .sec_08 {
    padding-bottom: 30px;
  }
}

.sec_08 .customer > div {
  font-weight: bold;
}

.sec_08 .voice > div {
  padding: 20px 0;
}

@media only screen and (max-width: 640px) {
  .sec_08 .voice > div {
    padding: 0;
  }
}

.sec_08 span {
  position: relative;
  display: inline-block;
  margin: 1.5em 0 1.5em 15px;
  padding: 0 10px;
  width: auto;
  line-height: 2.5;
  vertical-align: middle;
  text-align: center;
  color: #333;
  background: #e6e6fa;
  border-radius: 15px;
  box-sizing: border-box;
}

.sec_08 span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -25px;
  margin-top: -15px;
  border: 15px solid transparent;
  border-right: 15px solid #e6e6fa;
  z-index: 0;
}

@media only screen and (max-width: 640px) {
  .gallery {
    display: block;
  }
  .gallery .box {
    width: 100% !important;
  }
}

.gallery .box {
  width: 30%;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .gallery .box {
    width: 33% !important;
    text-align: center;
  }
}

.gallery .box img {
  width: 350px !important;
  height: 350px !important;
  object-fit: cover;
  margin-bottom: 10px;
}

@media only screen and (max-width: 640px) {
  .gallery .box img {
    width: 100% !important;
    height: auto !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .gallery .box img {
    width: 200px !important;
    height: 200px !important;
    object-fit: cover;
    margin-bottom: 50px;
  }
}

.quewrap {
  background: #f7f6fb;
  padding: 20px;
  border-radius: 15px;
  font-family: source-han-sans-japanese, sans-serif;
}

@media only screen and (max-width: 640px) {
  .quewrap {
    padding: 0;
  }
}

.quewrap .answer > div {
  padding: 10px 0;
}

.quewrap .answer > div a {
  color: #ff0000;
}

.quewrap .answer > div a:hover {
  text-decoration: underline;
}

.quewrap .answer > div:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #b39e05;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  margin-right: 7px;
}

.que > div {
  font-weight: bold;
  border-bottom: 1px dotted #d1a128;
  padding: 10px 0;
  color: #03386f;
}

.que > div:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #03386f;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  margin-right: 7px;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 768px) {
  .bg_100per_wrap {
    padding: 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 54%;
  position: absolute;
  top: 410px;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/patern.gif);
  background-size: cover;
  z-index: -1;
}

@media only screen and (max-width: 640px) {
  .bg_100per_inner {
    height: 96%;
    top: 40px;
  }
}

.bg_100per_wrap2 {
  position: relative;
  padding: 120px 0 !important;
}

@media only screen and (max-width: 768px) {
  .bg_100per_wrap2 {
    padding: 30px !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.con100 {
  position: relative;
  padding: 50px 0 !important;
}

.con100_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #f7f6fb;
  background-size: cover;
  z-index: -1;
}
